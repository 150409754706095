export const klaroConfig = {
    // cookie name
    storageName: "klaro-booking",

    // do not show klaro branding
    disablePoweredBy: true,

    hideDeclineAll: true,

    // add the "accept all" button
    acceptAll: true,

    // default all options initially to "true"

    translations: {
        zz: {
            privacyPolicyUrl: "/holibob-privacy-policy",
            purposes: {
                analytics: {
                    title: "Analytics",
                },
            },
        },

        fr: {
            consentModal: {
                title: "Services que nous aimerions utiliser",
                description:
                    "Ci-dessous vous pouvez personnaliser les services que nous utilisons sur notre site internet. C'est vous qui décidez ! Activez ou désactivez les services comme vous le souhaitez.",
            },
            consentNotice: {
                title: "Consentement aux cookies",
                description:
                    "Bonjour! Nous souhaiterions activer certains services supplémentaires pour {purposes}?. Merci d'accepter ou refuser. Vous gardez le droit de modifier ou retirer votre consentement ultérieurement.",
            },
            service: {
                purpose: "Objectif",
            },
            acceptSelected: "Accepter les éléments sélectionnés",
            ok: "J'accepte",

            purposes: {
                analytics: {
                    title: "Analyse des données",
                },
            },
        },

        es: {
            acceptSelected: "Aceptar selección",
            decline: "Rechazar todas",
            consentModal: {
                description:
                    "Aquí puedes evaluar y personalizar los servicios que nos gustaría utilizar en nuestra página web. Puedes habilitar o deshabilitar los servicios que deseas.",
            },
            consentNotice: {
                title: "Consentimiento de cookies",
            },
            privacyPolicy: {
                text: "Para más información, aquí encontrarás nuestra {privacyPolicy}",
            },

            purposes: {
                analytics: {
                    title: "Analitica",
                },
            },
        },

        de: {
            consentModal: {
                description:
                    "Hier können Sie die Dienste, die wir auf dieser Website nutzen möchten, bewerten und anpassen. Entscheiden Sie selbst! Aktivieren oder deaktivieren Sie die Dienste, wie Sie es für zweckmäßig halten.",
            },
            privacyPolicy: {
                text: "Um mehr zu erfahren, lesen Sie bitte unsere {privacyPolicy}",
            },
        },

        ar: {
            acceptAll: "قبول الكل",
            acceptSelected: "قبول المحدد",
            close: "قريب",
            consentModal: {
                description:
                    "هنا يمكنك تقييم الخدمات التي نرغب في استخدامها على هذا الموقع وتخصيصها. أنت المسؤول! تمكين أو تعطيل الخدمات كما تراه مناسبا.",
                title: "الخدمات التي نود استخدامها",
            },
            consentNotice: {
                changeDescription: "كانت هناك تغييرات منذ زيارتك الأخيرة ، يرجى تجديد موافقتك.",
                description:
                    "أهلاً! هل يمكننا من فضلك تمكين بعض الخدمات الإضافية لـ {purposes}؟ يمكنك دائمًا تغيير موافقتك أو سحبها لاحقًا.",
                learnMore: "دعني اختار",
                testing: "وضع الاختبار!",
                title: "اعدادات الخصوصية",
            },
            contextualConsent: {
                acceptAlways: "دائما",
                acceptOnce: "نعم فعلا",
                description: "هل تريد تحميل المحتوى الخارجي المقدم من {title}؟",
            },
            decline: "أنا أرفض",
            ok: "هذا حسن",
            poweredBy: "أدركت مع Klaro!",
            privacyPolicy: {
                name: "سياسة خاصة",
                text: "لمعرفة المزيد ، يرجى قراءة {privacyPolicy}.",
            },
            purposeItem: {
                service: "الخدمات",
                services: "خدمات",
            },
            purposes: {
                advertising: {
                    description: "تعالج هذه الخدمات المعلومات الشخصية لتظهر لك إعلانات مخصصة أو قائمة على الاهتمامات.",
                    title: "إعلان",
                },
                functional: {
                    description:
                        "هذه الخدمات ضرورية للتشغيل الصحيح لهذا الموقع. لا يمكنك تعطيلها هنا لأن الخدمة لن تعمل بشكل صحيح بخلاف ذلك.",
                    title: "تقديم الخدمات",
                },
                marketing: {
                    description:
                        "تعالج هذه الخدمات المعلومات الشخصية لتظهر لك محتوى ذي صلة بالمنتجات أو الخدمات أو الموضوعات التي قد تكون مهتمًا بها.",
                    title: "تسويق",
                },
                performance: {
                    description: "تعالج هذه الخدمات المعلومات الشخصية لتحسين الخدمة التي يقدمها هذا الموقع.",
                    title: "تحسين الأداء",
                },
                analytics: {
                    title: "التحليلات",
                },
            },
            save: "حفظ",
            service: {
                disableAll: {
                    description: "استخدم رمز التبديل هذا لتمكين أو تعطيل كافة الخدمات.",
                    title: "تمكين أو تعطيل كافة الخدمات",
                },
                optOut: {
                    description: "يتم تحميل هذه الخدمات بشكل افتراضي (ولكن يمكنك إلغاء الاشتراك)",
                    title: "(انسحب)",
                },
                purpose: "هدف",
                purposes: "المقاصد",
                required: {
                    description: "هذه الخدمات مطلوبة دائمًا",
                    title: "(مطلوب دائما)",
                },
            },
        },
    },
    services: [
        {
            name: "googleAnalytics",
            purposes: ["analytics"],
            translations: {
                zz: {
                    title: "Google Analytics",
                },
                es: {
                    title: "Analitica de Google",
                },
            },
        },
        {
            name: "googleTagManager",
            purposes: ["analytics"],
            translations: {
                zz: {
                    title: "Google Tag Manager",
                },
            },
        },
        {
            name: "hotjar",
            purposes: ["analytics"],
            translations: {
                zz: {
                    title: "Hotjar",
                },
            },
        },
        {
            name: "metaPixel",
            purposes: ["analytics"],
            translations: {
                zz: {
                    title: "Meta Pixel",
                },
            },
        },
    ],
} as const;

export type KlaroConfig = typeof klaroConfig & { lang?: string; noticeAsModal?: boolean; default?: boolean };
export type KlaroServices = KlaroConfig["services"][number]["name"];
